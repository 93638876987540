/* eslint-disable @nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UntypedFormControl, Validators } from '@angular/forms';
import { Flow_0_0_2, getFullItemTwo, getSubmissionData, getCurrentUser } from '@flexus/core';
import { CollapseActionPanel, setActionPanelItems } from '../../../../app-shell-features';
import { combineLatest, forkJoin, of, take, map, switchMap, filter  } from 'rxjs';
import { environment } from 'apps/studio/src/environments/environment';
import { CustomValidators, getExtension } from '@flexus/utilities';

export const PGG_46: Flow_0_0_2 = {
	id: '46',
	name: 'invoice-query',
	itemType: 'flow',
	actionPanel: (instance: any) => setActionPanelItems(instance, ['job-details', 'notes', 'documents']),
	onStateInit: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	onStateDestroy: (inst: any) => {
		inst.store.dispatch(new CollapseActionPanel());
	},
	header: {
		title: (store: any) => {
			return store.select(getFullItemTwo).pipe(
				filter(Boolean),
				take(1),
				map((itemTwo: any) => {
					if (itemTwo) {
						if (itemTwo?.claim?.applicant && itemTwo?.claim?.loan_information) {
							return `Invoice Query : ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name}`;
						} else {
							return 'Invoice Query';
						}
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	serverCalls: {
		skills: {
			serviceVariable: 'spService',
			functionName: 'getSkills',
			responseSlice: 'skills',
			errorMessage: 'No skills were found!'
		},
		dataFile: {
			errorMessage: 'No files found',
			serviceVariable: 'spService',
			functionName: 'getJobInvoice'
		},
		keyValueList1: {
			errorMessage: `Couldn't get the job info`,
			directCall: (http: any, store: any) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						filter(Boolean),
						take(1),
					),
					http.get(`${environment.api_url}v1/staff_action/get_sp_team_leaders/`).pipe(
						filter(Boolean),
						take(1),
						map((data: any) => data?.payload)
					)
				]).pipe(
					map(([job, teamleaders]: any) => {
						const leads = teamleaders as any;
						let teamleadname, invoiceQuery;
						if (!job?.team_leader) {
							teamleadname = 'No team leader appointed.';
						} else if (job?.team_leader) {
							for (let i = 0; i < leads.length; i++) {
								if (leads[i]?.id === job?.team_leader.id) {
									teamleadname = leads[i]?.full_name;
								}
							}
						}
						const invoiceQueryObj = job?.job_information?.invoicequery;
						if (Array.isArray(invoiceQueryObj)) {
							const invQueryIndex = invoiceQueryObj.length - 1;
							invoiceQuery = invoiceQueryObj[invQueryIndex].query;
						} else {
							invoiceQuery = invoiceQueryObj.query;
						}

						const list = {
							'Reason for invoice query 1 ': invoiceQuery,
							'Allocated team leader ': teamleadname,
							'Claim Type  ': job?.claim?.type ?? '',
							'Address  ': job?.address ?? '',
							'Appointment Date  ': job?.office_use?.requestedappointmentdate ?? 'No appointment date set',
							'Appointment Time  ': job?.office_use ? `${job.office_use.appointment_type} ${job.office_use.requestedappointmenttime}` : 'Appointment time not retrieved'
						};
						return [list];
					})
				);
			}
		}
	},
	instructions: {
		editRoles: {
			0: 'Respond to invoice query'
		},
		viewRoles: {
			0: 'Wait for SP to respond'
		}
	},
	startNode: 'InvoiceQuery',
	nodes: {
		InvoiceQuery: {
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: {
							inputHeading: 'Invoice Query',
							dataFiles$: 'dataFile',
							keyValueList$: 'keyValueList1',
							instructions: ['There is a query on the invoice', 'Please read below and address the query']
						}
					}
				]
			},
			navs: [
				{ text: 'Reply, no new invoice', color: 'default', nextNode: 'NoteOnInvoiceQuery' },
				{
					text: 'Upload new invoice',
					color: 'default',
					nextNode: 'UploadInvoice',
					optIntoValidation: true
				}
			]
		},
		UploadInvoice: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: bf => {
				bf.bigForm.addControl('purpose', new UntypedFormControl('invoice'));
				bf.bigForm.addControl('fileQueue', new UntypedFormControl([], [Validators.required, Validators.minLength(1)]));
			},
			checkValidityForFields: ['purpose', 'fileQueue'],
			component: {
				children: [
					{
						component: 'FLXFileUploadWithPreviewComponent',
						inputs: {
							purposes: [{ display: 'Invoice', value: 'invoice' }],
							heading: 'Select the invoice to attach',
							multipleUploads: false,
							allowedFileTypes: ['pdf', 'image'],
							containerWidth: '50vw',
							maxWidth: '50vw'
						}
					}
				]
			},
			navs: [
				// {
				// 	text: 'request additional fees',
				// 	color: 'secondary',
				// 	nextNode: 'RequestAdditionalFeesReason'
				// },
				// {
				// 	text: 'Return to TL',
				// 	color: 'secondary',
				// 	nextNode: 'ReturnToTLReasonEntry'
				// },
				{
					text: 'Upload Invoice',
					nextNode: 'FileSubmissionSuccess1',
					optIntoValidation: true,
					color: 'primary',
					linkType: 'submit',
					serverFirst: true,
					serverCalls: {
						response: {
							errorMessage: 'Invoice could not be uploaded',
							directCall: (http: any, store: any, sq: any, bf: any) => {
								const fileQueue = bf.bigForm.get('fileQueue')?.value as Array<any>;
								const body: object = {
									data: fileQueue[0] ? fileQueue[0]?.file.base64 : '',
									job_id: bf.bigForm.get('itemTwo')?.value?.id,
									purpose: bf.bigForm.get('purpose')?.value,
									filename: `${bf.bigForm.get('purpose')?.value}-${bf.bigForm.get('itemTwo')?.value?.id}${getExtension(fileQueue[0]?.file?.nativeFile?.name)}`
								};
								return http.post(`${environment.api_url}v1/file_action/upload_file/`, body, {
									observe: 'events',
									reportProgress: true
								});
								return of({});
							}
						}
					}
				}
			]
		},
		NoteOnInvoiceQuery: {
			showTabs: false,
			hideTabItem: true,
			initFormFields: (bf: any, item: any, instance: any, storeQuery: any, store: any) => {
				combineLatest([store.select(getCurrentUser).pipe(filter(x => !!x, take(1))), store.select(getFullItemTwo).pipe(filter(x => !!x, take(1)))])
					.pipe(take(1))
					.subscribe(([author, job]: any) => {
						bf.patchValues({ author: author?.user?.id });
						bf.patchValues({ author_name: author?.user?.full_name });
						bf.patchValues({ currentState: job?.state });
					});
				bf.addControl('reply_to_invoicequery', new UntypedFormControl('', [Validators.required]));
			},
			component: {
				children: [
					{
						component: 'FLXFlatDynamicFormComponent',
						inputs: {
							heading: 'Reply to Internal Assessor Query',
							formControls: {
								0: {
									label: 'Please enter your comments here',
									inputType: 'textarea',
									rows: 5,
									formControlName: 'reply_to_invoicequery'
								}
							},
							formLayout: 'stacked',
							containerWidth: '50vw',
							headingSize: 'medium',
							headingWeight: 'light',
							headingType: 'creation',
							headingMargin: '30px 0 75px 0'
						}
					}
				]
			},
			navs: [
				{
					text: 'Continue',
					color: 'primary',
					nextNode: 'SubmissionSuccess',
					serverFirst: true,
					visible: (bf) => {
						return bf.getControl('reply_to_invoicequery').valueChanges.pipe(map(value => bf.getControl('reply_to_invoicequery').valid && value !== ''));
					},
					serverCalls: {
						response: {
							errorMessage: 'Job could not be updated!!',
							directCall: (http: any, store: any) => {
								return forkJoin([
									store.select(getFullItemTwo).pipe(
										filter(Boolean),
										take(1)
									),
									store.select(getSubmissionData).pipe(
										filter(Boolean),
										take(1)
									)
								]).pipe(
									map(([job, submit]: any) => {
										const job_id = job?.id;
										const current_state = job?.state;
										const new_state = 48;
										return { job_id, current_state, new_state, ...submit };
									}),
									switchMap(data => {
										return http.post(`${environment.api_url}v1/job_action/update_job/`, data);
									})
								);
							}
						}
					}
				}
			]
		},
		FileSubmissionSuccess1: {
			hideTabItem: true,
			component: 'FLXSuccessTickComponent',
			inputs: { autoClose: false, heading: 'File Added to Upload Queue' },
			navs: [
				{
					text: 'Continue',
					nextNode: 'WriteJobTotal'
				}
			]
		},
		WriteJobTotal: {
			serverCalls: {
				invoice: {
					serviceVariable: 'mulSpService',
					functionName: 'getJobInvoice',
					errorMessage: 'Could not get files from server!'
				}
			},
			checkValidityForFields: ['job_total'],
			hideTabItem: true,
			showTabs: false,
			initFormFields: (bf) => {
				bf.patchValues({ new_state: 48 });
				bf.bigForm.addControl('job_total', new UntypedFormControl('', [Validators.required, CustomValidators.noSpaces]));
			},
			component: {
				children: [
					{
						component: 'FLXFileViewWithSideExtrasComponent',
						inputs: {
							dataFiles$: 'invoice',
							inputHeading: 'Confirm New Invoice Amount',
							formInputs: {
								0: {
									formControlName: 'job_total',
									inputType: 'currency',
									label: 'Enter invoice total.'
								}
							},
							formLayout: 'three-columnm',
							containerWidth: '20vw'
						}
					}
				]
			},
			navs: [
				// {
				// 	text: 'return to team leader',
				// 	linkType: 'portal',
				// 	color: 'secondary',
				// 	nextNode: 'ReturnToTLReasonEntry',
				// 	serverCalls: {}
				// },
				{
					text: 'submit',
					color: 'primary',
					serverFirst: true,
					optIntoValidation: true,
					serverCalls: {
						response: {
							serviceVariable: 'pggSpService',
							functionName: 'updateJob',
							errorMessage: `Couldn't update job`
						}
					},
					nextNode: 'SubmissionSuccess'
				}
			]
		},
		SubmissionSuccess: { component: 'FLXSuccessTickComponent' }
	},

	bigFormToStoreMapper: {
		currentState: [() => 46, 'current_state'],
		new_state: [() => 48, 'new_state'],
		reply_to_invoicequery: [
			(rtiq, storeObj) => {
				let ia_query_replies = [];
				if (storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery) {
					let temp = storeObj['selectedContext']?.fullItemTwo?.job_information?.invoicequery;
					if (Array.isArray(temp)) {
						const lastquery = temp[temp.length - 1].query;
						const updatedQueryObj = { query: lastquery, queryreply: rtiq };
						temp = temp.slice(0, temp.length - 1);
						ia_query_replies = [...temp, updatedQueryObj];
					} else {
						ia_query_replies = [
							{
								...temp,
								queryreply: rtiq
							}
						];
					}
				}
				return ia_query_replies;
			},
			'job_information.invoicequery'
		],
		total: [
			(a) => {
				// return bf.total + bf.vatRate;
				return a;
			},
			'job_information.quote_amount'
		],
		invoiceNumber: 'job_information.quote_number'
	}
};
