/* eslint-disable @typescript-eslint/no-explicit-any */
import { Flow_0_0_2, getFullItemTwo } from '@flexus/core';
import { filter, map, take } from 'rxjs';

export const PGG_SP_CONTEXT_MENU_VIEW_DOCUMENTS: Flow_0_0_2 = {
	id: 'ViewDocuments',
	itemType: 'flow',
	// excludeForOnlyRoles: [18],
	actionPanel: () => {
		return () => {
			return [{}];
		};
	},
	name: 'View Documents',

	header: {
		title: store => {
			return store.select(getFullItemTwo).pipe(
				filter(Boolean),
				take(1),
				map((itemTwo: any) => {
					if (itemTwo) {
						return `View Documents : ${itemTwo?.claim?.loan_information?.voucher_key} - ${itemTwo?.claim?.applicant?.first_name}`;
					} else {
						return 'View Documents';
					}
				})
			);
		},
		controls: () => () => []
	},
	footer: {
		type: 'node_nav'
	},
	startNode: 'ViewDocuments',
	instructions: {
		editRoles: {
			0: ''
		},
		viewRoles: {
			0: ''
		}
	},
	serverCalls: {
		files: {
			serviceVariable: 'silService',
			functionName: 'getAllJobFiles',
			responseSlice: 'payload',
			errorMessage: 'Could not get files from server!'
		}
	},
	nodes: {
		ViewDocuments: {
			component: 'ViewDocumentsComponent',
			inputs: {
				heading: 'View Documents',
				location: 'context-menu',
				selectDocsToView: ['photos']
			},
			navs: []
		}
	}
};
