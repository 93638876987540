/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Input, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Observable, Subscription, take, map, skipUntil, filter, skipWhile, switchMap } from 'rxjs';
import { getCurrentUser, NetworkService, getAllInfo, ManifestController, getItemOneContextMenu, getItemTwoContextMenu } from '@flexus/core';
import { getPermissions, getActiveOrganization } from '@flexus/core';
import { select, Store } from '@ngrx/store';
import { getCurrentPage } from '@flexus/ux';
import { findName } from '@flexus/utilities';

@Component({
	selector: 'flx-list-view',
	templateUrl: './list-view.component.html',
	styleUrls: ['./list-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FLXListViewComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() list$: Observable<any>;
	currentPage: number;
	pageSize = 30;
	itemOneContextMenuList = [];
	itemTwoContextMenuList = [];
	itemOnePermissions: Function[] = [];
	itemTwoPermissions: Function[] = [];
	allInfo$: Observable<any>;
	user$: Observable<any>;
	isOnline$: Observable<boolean>;
	onLineSub: Subscription;
	userSub: Subscription;
	activeOrg$: Observable<any>;
	claimTypeDescriptions$: Observable<any>;
	stateDescriptions$: Observable<any>;
	indicatorObservable$: Observable<any>;
	loading$;
	claimContextMenuList = [];

	subs: any[] = [];

	currentPageSubscription: Subscription;

	constructor(private _store: Store<any>, private controller: ManifestController<any>, private network: NetworkService, private cdr: ChangeDetectorRef) {}

	ngOnInit() {
		this.getAllInfo();
		this.getActiveOrg();
		this.getNetworkState();
		this.getUser();
		this.getPermissions();
		this.getCurrentPage();
		this.getClaimContextMenuList();
		this.getJobContextMenuList();
	}

	ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	get theList$() {
		return this.list$.pipe(
			skipUntil(this.allInfo$),
			skipUntil(this.activeOrg$),
			filter(x => !!x),
			switchMap(list => {
				return this._store.select(getAllInfo).pipe(
					filter(x => !!x),
					take(1),
					map((allInfo: any) => {
						list.forEach(element => {
							if (element.jobs) {
								element.jobs.forEach(item => {
									item.skillName = findName(item?.skill, allInfo.skills).name;
									item.stateName = findName(item?.state, allInfo.states)?.description;
								});
							}
						});
						return list;
					})
				);
			})
		);
	}

	private getActiveOrg() {
		this.activeOrg$ = this.controller.select(getActiveOrganization);
	}

	private getNetworkState() {
		this.isOnline$ = this.network.isOnline;
	}

	trackByFunc(idx, claim) {
		return claim?.id;
	}

	private getUser() {
		this.user$ = this._store.select(getCurrentUser);
	}

	getCurrentPage() {
		this.currentPageSubscription = this._store.select(getCurrentPage).subscribe(pageNum => {
			this.currentPage = pageNum;
			this.cdr.detectChanges();
		});
	}

	getPermissions() {
		this.controller
			.select(getPermissions)
			.pipe(take(1))
			.subscribe(perm => {
				if (perm) {
					console.log({ perm });
					this.itemOnePermissions = Object.values(perm['itemOne']);
					this.itemTwoPermissions = Object.values(perm['itemTwo']);
				}
			});
	}

	private getAllInfo() {
		this.allInfo$ = this._store.pipe(select(getAllInfo));
	}

	getClaimContextMenuList() {
		this.controller
			.select(getItemOneContextMenu)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe((contextMenu: any) => {
				Object.entries(contextMenu).forEach(([key, value]) => {
					this.itemOneContextMenuList.push({
						id: value['id'],
						name: value['name'],
						key,
						includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
						excludeForOnlyStates: value['excludeForOnlyStates'] || [],
						includeFilterFunction: value['includeFilterFunction']
					});
				});
			});
	}

	getJobContextMenuList() {
		this.controller
			.select(getItemTwoContextMenu)
			.pipe(
				skipWhile(x => !x),
				take(1)
			)
			.subscribe((contextMenu: any) => {
				Object.entries(contextMenu).forEach(([key, value]) => {
					this.itemTwoContextMenuList.push({
						name: value['name'],
						key,
						includeForOnlyStates: value['includeForOnlyStates'] || ['all'],
						excludeForOnlyStates: value['excludeForOnlyStates'] || [],
						includeFilterFunction: value['includeFilterFunction']
					});
				});
			});
	}

	ngOnDestroy() {
		if (this.currentPageSubscription) {
			this.currentPageSubscription.unsubscribe();
		}
		if (this.onLineSub) {
			this.onLineSub.unsubscribe();
		}
	}
}
