/* eslint-disable @typescript-eslint/no-explicit-any */
import { NodePoint_0_0_2, getFullItemTwo, getAllInfo } from '@flexus/core';
import { forkJoin, take, map, filter  } from 'rxjs';
import { humaniseDate } from '@flexus/utilities';
import moment from 'moment';
import { KVLHeading } from '@flexus/ui-elements';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { DecimalPipe } from '@angular/common';

export const JOB_INFO_NODE: NodePoint_0_0_2 = {
	showTabs: true,
	name: 'Overview',
	initFormFields: () => {},
	component: {
		children: [
			{
				component: 'FLXHeadingWithInstructionsComponent',
				inputs: {
					title: 'Job Allocation',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					colouredHeading: new KVLHeading('Installation Details', 'secondary'),
					data$: 'claimDetailKeyValues',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					colouredHeading: new KVLHeading('Payment Details', 'secondary'),
					data$: 'paymentKeyValues',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					colouredHeading: new KVLHeading('On-site Details', 'secondary'),
					data$: 'onsiteKeyValues',
					itemMargin: '0 0 35px 0'
				}
			},
			{
				component: 'FLXKeyValueListComponent',
				inputs: {
					colouredHeading: new KVLHeading('Customer Details', 'secondary'),
					data$: 'customerContactKeyValues',
					itemMargin: '0 0 35px 0'
				}
			}
		]
	},
	serverCalls: {
		claimDetailKeyValues: {
			errorMessage: 'Something went wrong claim details',
			directCall: (http, store) => {
				return forkJoin([
					store.select(getFullItemTwo).pipe(
						filter(Boolean),
						take(1)
					),
					store.select(getAllInfo).pipe(
						filter(Boolean),
						take(1),
						map((data: any) => data.skills)
					)
				]).pipe(
					map(([itemTwo, skills]: any) => {
						let appointment: any;
						const skillOnJob: number = itemTwo?.skill;
						const skillFound: any = skills.find((skill: any) => skill.id === skillOnJob);
						const skillDescription: string = skillFound.name;
						const product: string = itemTwo?.claim?.loan_information?.voucher_type ?? 'No voucher type';
						console.log('ITEMTOW', itemTwo);
						console.log('SKILLS', skills);

						if (itemTwo?.appointment && itemTwo?.appointment.length > 0) {
							appointment = itemTwo?.appointment[0];
						} else {
							appointment = {range_start: null};
						}
						const claimdetaillist = {
							Product: `${product}`,
							'Skill required ': `${skillDescription}` ?? '--',
							'Address ': itemTwo?.address ?? '',
							'Appointment Date': appointment.range_start ? `${humaniseDate(appointment.range_start)}` : 'No appointment date set',
							'Appointment Time': appointment.range_start ? `${moment(appointment.range_start)?.format('HH:mm')}` : 'Appointment time not retrieved'
						};
						return [claimdetaillist];
					})
				);
			}
		},
		paymentKeyValues: {
			errorMessage: 'Could not get payment detail',
			directCall: (_http: HttpClient, _store: Store) => {
				return _store
					.select(getFullItemTwo)
					.pipe(
						filter(Boolean),
						take(1)
					)
					.pipe(
						map((job: any) => {
							let pre_job_extra_fee_display;
							let post_job_extra_fee_display;
							// const approved_sp_job_fee_display;
							const approved_sp_job_fee = parseFloat(job?.claim?.loan_information?.values?.sp_value);
							const pre_job_extra_fee = job?.job_information?.installer_incentive_amount ? parseFloat(job?.job_information?.installer_incentive_amount) : 0;
							const post_job_extra_fee = job?.job_information?.additional_fees_amount ? parseFloat(job?.job_information?.additional_fees_amount) : 0;
							if (pre_job_extra_fee) {
								pre_job_extra_fee_display = new DecimalPipe('en-US').transform(Number(pre_job_extra_fee), '1.2-2');
							} else {
								pre_job_extra_fee_display = new DecimalPipe('en-US').transform(Number(0), '1.2-2');
							}
							if (post_job_extra_fee) {
								post_job_extra_fee_display = new DecimalPipe('en-US').transform(Number(post_job_extra_fee), '1.2-2');
							} else {
								post_job_extra_fee_display = new DecimalPipe('en-US').transform(Number(0), '1.2-2');
							}
							const approved_sp_job_fee_display = new DecimalPipe('en-US').transform(Number(approved_sp_job_fee), '1.2-2');
							const approved_total = new DecimalPipe('en-US').transform(Number(approved_sp_job_fee + pre_job_extra_fee + post_job_extra_fee), '1.2-2');
							const paymentkeyvalues = {
								'Approved SP Job Fee (Excl. VAT)': `R${approved_sp_job_fee_display}`,
								'Pre-job SP Extras Fee (Excl. VAT)': `R${pre_job_extra_fee_display}`,
								'Post-job SP Extras Fee (Excl. VAT)': `R${post_job_extra_fee_display}`,
								'': '',
								'Approved SP Cost (Excl. VAT)': `R${approved_total}`
							};
							return [paymentkeyvalues];
						})
					);
			}
		},
		onsiteKeyValues: {
			errorMessage: "Couldn' retrieve on-site info",
			directCall: (http, store) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						filter(Boolean),
						take(1)
					)
					.pipe(
						map((itemTwo: any) => {
							const onsitelist = {
								'On site contact person ': itemTwo?.claim?.loan_information?.onsiteperson ?? 'Not retrieved',
								'Contact number ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'On-site contact number not available',
								'Notes ': itemTwo?.claim?.loan_information?.whatmatters ?? 'What Matters Not Retrieved'
							};
							return [onsitelist];
						})
					);
			}
		},
		customerContactKeyValues: {
			errorMessage: 'Customer contact information could not be retrieved',
			directCall: (http, store) => {
				return store
					.select(getFullItemTwo)
					.pipe(
						filter(Boolean),
						take(1)
					)
					.pipe(
						map((itemTwo: any) => {
							const customercontactlist = {
								'Customer ': itemTwo?.claim?.applicant ? `${itemTwo?.claim?.applicant?.first_name} ` : 'Customer name not available',
								'Client cell ': itemTwo?.claim?.loan_information?.onsitecontact ?? 'Cellular number not on record'
							};
							return [customercontactlist];
						})
					);
			}
		}
	}
};
